import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Typography,
  Box,
  Container,
  Section,
  Layout,
  Grid,
} from '../components';
import styled from 'styled-components';
import ServiceHeroSection from '../components/ServiceHeroSection';
import bannerImage from '../images/services/cloudServices/cloudServicesBanner.svg';
import cloudStrategy from '../images/services/cloudServices/cloudStrategy.svg';
import cloudMigration from '../images/services/cloudServices/cloudMigration.svg';
import devOps from '../images/services/cloudServices/devOps.svg';
import security from '../images/services/cloudServices/security.svg';

const ServiceContentImage = styled.img`
  box-shadow: 25px 25px 0px 0px var(--secondary-color);
  margin: 25px 0;
  width: 100%;

  @media screen and (max-width: 768px) {
    box-shadow: 10px 10px 0px 0px var(--secondary-color);
  }
`;

const cloudService = () => {
  return (
    <Layout title="Cloud Services">
      <Helmet>
        <meta
          name="description"
          content="Access top-tier cloud experts for reliable, managed services. Customized strategies, seamless migrations, DevOps automation, and robust security for your cloud journey."
        />
      </Helmet>
      <Container mt={{ xs: 8, md: 12 }}>
        <ServiceHeroSection
          title={'Cloud Services'}
          imageSrc={bannerImage}
          imgAlt={'Cloud Services'}
        />
        <Section>
          <Typography my={5} variant="paragraph1">
            Get access to top-tier certified experts to navigate the cloud
            journey based on your objectives. We offer highly reliable and
            managed cloud services with transparent roadmaps and service-level
            agreements.
          </Typography>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box>
              <ServiceContentImage src={cloudStrategy} />
            </Box>
            <Box>
              <Typography mt={2} variant="heading5">
                Cloud Strategy
              </Typography>

              <Typography mt={2} variant="paragraph1">
                We conduct a thorough assessment of your IT infrastructure and
                develop a customized cloud roadmap to achieve your objectives.
              </Typography>
            </Box>
          </Grid>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box flip={true}>
              <Typography mt={2} variant="heading5">
                Cloud Migration
              </Typography>

              <Typography mt={2} variant="paragraph1">
                We tailor a migration plan that minimizes disruption, ensures
                data integrity, and optimizes your cloud environment for peak
                performance.
              </Typography>
            </Box>
            <Box>
              <ServiceContentImage
                style={{ float: 'right' }}
                src={cloudMigration}
              />
            </Box>
          </Grid>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box>
              <ServiceContentImage src={devOps} />
            </Box>
            <Box>
              <Typography mt={2} variant="heading5">
                DevOps
              </Typography>

              <Typography mt={2} variant="paragraph1">
                Our DevOps specialists streamline the process by implementing
                automated processes, infrastructure as code (IaC), and
                continuous integration/continuous delivery (CI/CD) pipelines.
              </Typography>
            </Box>
          </Grid>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box flip={true}>
              <Typography mt={2} variant="heading5">
                Security
              </Typography>

              <Typography mt={2} variant="paragraph1">
                We offer advanced access controls, encryption protocols, and
                ongoing security monitoring to ensure the confidentiality,
                integrity, and availability of your data.
              </Typography>
            </Box>
            <Box>
              <ServiceContentImage style={{ float: 'right' }} src={security} />
            </Box>
          </Grid>
        </Section>
      </Container>
    </Layout>
  );
};

export default cloudService;
